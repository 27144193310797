/* You can add global styles to this file, and also import other style files */
table > thead > tr > th {
  background-color: #014983 !important;
  color: white !important;
  font-weight: bold;
}

.modal-content .card {
  margin-bottom: 0px !important;
}

.card-footer button {
  margin-left: 2px;
  margin-right: 2px;
}

th::after, th::before{
  display: none !important;
}



.alert-danger {
  color: #721c24 !important;
  background-color: #f8d7da !important;
  border-color: #f5c6cb !important;
}

.alert-success {
  color: #155724 !important;
  background-color: #d4edda !important;
  border-color: #c3e6cb !important;
}

.alert-campos {
  margin-top: 10px !important;
  margin-bottom: 0px !important;
  bottom: 0 !important;
}

.sweetalert-confirmbutton-class {
  color: #ffffff !important;
  background-color: #5a6268 !important;
  border-color: #545b62 !important;
}

.sweetalert-cancelButton-class {
  background-color: #e9ecef !important;
  color: #2b2b2b !important;
}

th.acciones {
  width: 100px !important;
}

.sidebar-dark-primary .nav-sidebar > .nav-item > .nav-link.active,
.sidebar-light-primary .nav-sidebar > .nav-item > .nav-link.active {
  background-color: white !important;
  color: #343a40 !important;
}

/* .swal2-confirm.swal2-styled{
  color: #ffffff!important;
  background-color: #5a6268!important;
  border-color: #545b62!important;
}

.swal2-styled.swal2-confirm:focus {
  box-shadow: 0 0 0 3px #5a6268;
} */

.nav-link {
  cursor: pointer !important;
}

[class*="sidebar-dark-"] {
  background-color: #014983 !important;
}

.brand-link {
  border-bottom: 1px solid #4b545c !important;
  color: white !important;
  font-weight: bold !important;
  padding-left: 23px!important;
}

.brand-link > span {
  color: white !important;
  font-weight: bold !important;

}

.sidebar a {
  color: white !important;
}

.nav-treeview > .nav-item > .nav-link.active, 
.nav-treeview > .nav-item > .nav-link.active:hover, 
.nav-treeview > .nav-item > .nav-link.active:focus {
  background-color: white !important;
  color: #343a40 !important;
}

.card-secondary.card-outline {
  border-top: 3px solid #014983!important;
}

.btn-secondary {
  color: #ffffff;
  background-color: #014983!important;
  border-color: #014983!important;
  box-shadow: none;
}

.card-secondary:not(.card-outline) > .card-header {
  background-color: #014983!important; 
}


.edit-action {
  margin-right: 5px;
}

.custom-select-sm {
  width: 50px !important;
}
